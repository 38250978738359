<template>
  <modal
    v-if="''.length === 0"
    v-model="isModalOpen"
    auto-focus
    type="dark"
    class="modal-dark informationModal"
    :footer="false"
  >
    <div slot="title" v-if="appCode === 'vgk'">{{ $t('information_modal.information') }}</div>
    <div slot="title" v-if="appCode === 'ckad'">{{ $t('information_modal.information') }}</div>
    <div slot="title" v-if="appCode === 'ind'">{{ $t('information_modal.information_ind') }}<br>{{ $t('information_modal.information_ind_2') }}</div>
    <div class="text-center" v-if="appCode === 'vgk'">
      <p class="text-center" v-html="$t('information_modal.vgk_text')"></p>
      <p class="informationModal__descr" v-html="settings.vgk_descr"></p>
      <p class="informationModal__text">{{ $t('information_modal.soft_ka') }}
        <br/>
        <span v-html="settings.vgk_phone"></span> <br/>
        <a :href="'mailto:'+settings.vgk_email">{{ settings.vgk_email }}</a>
      </p>
      <div class="text-center"><img class="img-responsive center-block big-img" src="../../assets/img/vgk_logos.png"></div>
    </div>
    <div class="text-center" v-else-if="appCode === 'ckad'">
      {{ $t('information_modal.title_1') }} "{{ $t('information_modal.title_ckad') }}"<br><br>
      {{ $t('information_modal.po_ver') }} {{ settings.version }}<br><br>
      <span v-if="settings.license.length > 0">{{ $t('information_modal.license') }} {{ settings.license }}<br><br></span>
      <span v-else>{{ $t('information_modal.license') }} {{ license.type }} {{ license.organization }} {{ license.issued_date }}<br><br></span>
      {{ $t('information_modal.soft') }}<br><br>
      <a href="mailto:support@stdev.ru">support@stdev.ru</a>
      <img class="img-responsive center-block" src="../../assets/img/misc/info_logo.png"><br><br>
    </div>

    <div class="text-center" v-else-if="appCode === 'ind'">
      {{ $t('information_modal.title_1') }} "{{ $t('information_modal.title_frame') }}"<br><br>
      {{$t('information_modal.type')}} Bizmen 7/P-2L <br><br>
      {{$t('information_modal.sn')}} {{ settings.complex_name }}<br><br>
      {{ $t('information_modal.po_ver') }} {{ settings.version }}<br><br>
      {{$t('information_modal.manufacturer')}} {{ $t('information_modal.kt') }}<br><br>
      {{$t('information_modal.y_manufacture')}} 2021<br><br>
      {{ $t('information_modal.license') }} {{ $t('information_modal.license_valid') }}<br><br>
      {{ $t('information_modal.soft') }}<br><br>
      <a href="mailto:support@stdev.ru">support@stdev.ru</a>
      <img class="img-responsive center-block" src="../../assets/img/misc/info_logo.png"><br><br>
    </div>
  </modal>
  <modal
    v-else
    v-model="isModalOpen"
    auto-focus
    type="dark"
    class="modal-dark informationModal"
    :footer="false"
  >
    <div slot="title">{{ $t('information_modal.information') }}</div>
    <div class="text-center" >
<!--      <div v-html="settings.platon_descr"></div>-->
      <img class="img-responsive center-block" src="../../assets/img/misc/info_logo.png"><br><br>
    </div>
  </modal>
</template>

<script>
import { mapState } from 'vuex';
import modal from '@/components/mixins/modal';
import mixin from '@/components/mixins/mixin';

export default {
  name: 'InformationModal',
  mixins: [modal, mixin],
  computed: {
    ...mapState(['settings', 'license']),
    ...mapState({
      isModalOpenFromStore: (state) => state.isModalOpen.information,
    }),
  },
  methods: {
    close() {
      this.closeModal('information');
    },
  },
};
</script>
