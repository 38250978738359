import { mapState, mapGetters, mapMutations } from 'vuex';
import InfiniteLoading from 'vue-infinite-loading';
import AxlesConstructor from '@/components/panelGrid/AxlesConstructor.vue';
import GrnzPlate from '@/components/GrnzPlate/GrnzPlate.vue';
import mixin from '@/components/mixins/mixin';
import MeasurementFilter from '@/components/modals/MeasurementFilter.vue';
import ResetFilter from '@/components/modals/ResetFilter.vue';
import reportsControl from '@/components/reportsControl/reportsControl.vue';

export default {
  components: {
    InfiniteLoading,
    GrnzPlate,
    reportsControl,
    ResetFilter,
    MeasurementFilter,
    AxlesConstructor,
  },
  mixins: [mixin],
  props: ['filtersForRequest', 'activeFilter', 'filtersParent'],
  data() {
    return {
      measurements: [],
      offset: 0,
      socketId: -100,
      infiniteState: null,
    };
  },
  computed: {
    ...mapGetters([
      'timezoneTitle',
    ]),
    ...mapState([
      'wsMessage',
      'timezone',
      'settings',
    ]),
  },
  watch: {
    filtersForRequest() {
      this.unsubscribeMeasurements();
      this.measurements = [];
      this.offset = 0;
      this.subscribeMeasurements();
      this.measurements = [];
      this.offset = 0;
    },
    wsMessage(data) {
      if (data.requestId === this.measurementsRequestId) {
        if (this.resetMeasurements) {
          this.measurements = [];
          this.offset = 0;
        } else if (data.index === this.socketId && data.status === 'ok' && Array.isArray(data.data)) {
          this.offset += this.getNewLength(data.data, this.measurements, 'id');
          this.measurements = this.getUnique([...this.parseMeasurements(data.data), ...this.measurements], 'id');
        }
      }
    },
  },
  mounted() {
    this.subscribeMeasurements();
  },
  beforeDestroy() {
    this.unsubscribeMeasurements();
  },
  methods: {
    ...mapMutations(['openModal']),
    infiniteHandler($state) {
      this.infiniteState = $state;
      const request = {
        method: 'measurements_feed.get',
        token: localStorage.token,
        data: {
          params: {
            limit: 10,
            offset: this.offset,
          },
          filters: this.filtersForRequest,
        },
      };
      this.offset += 10;
      this.sendRequest(request).then((data) => {
        if (Array.isArray(data.data)) {
          if (data.data.length) {
            this.measurements = this.getUnique([...this.measurements, ...this.parseMeasurements(data.data)], 'id');
            if (this.infiniteState !== false) {
              this.infiniteState.loaded();
            }
          } else {
            this.infiniteState.complete();
          }
        }
      });
    },
    parseMeasurements(data) {
      return data.map((item) => {
        const value = { ...item, ...this.measurementAddParameters(item) };
        value.truckImage = this.getTruckImage(value);
        value.zsdImage = this.getZsdImage(value);
        return value;
      });
    },
    subscribeMeasurements() {
      this.measurementsRequestId = this.getRequestId();
      this.unsubscribeMeasurements();
      const request = {
        method: 'measurements_feed.subscribe',
        token: localStorage.token,
        data: {
          params: {
            limit: 10,
            offset: 0,
          },
          force: true,
          filters: this.filtersForRequest,
          requestId: this.measurementsRequestId,
        },
        index: this.socketId,
      };
      this.sendRequest(request, true).then(() => {
        console.log('sendRequest end');
        this.resetMeasurements = false;
        this.measurements = [];
        this.measurementsSize = 0;
      });
    },
    unsubscribeMeasurements() {
      this.resetMeasurements = true;
      const request = {
        method: 'measurements_feed.unsubscribe',
        token: localStorage.token,
        data: {},
        index: this.socketId,
      };
      this.sendRequest(request);
      this.measurements = [];
      this.measurementsSize = 0;
    },
    measurementRfidClass(measurement) {
      let measurementClass = '';
      if (this.appCode === 'ckad') {
        if (measurement.dsrc_id || measurement.rfid) {
          measurementClass = 'green';
        } else
        if (measurement.gnz || measurement.back_cam_gnz) {
          measurementClass = 'yellow';
        } else
        if (!measurement.gnz && !measurement.back_cam_gnz) {
          measurementClass = 'red';
        }
      } else if (this.appCode === 'ind') {
        if (measurement.total_weight > 0 && measurement.gnz && measurement.class_ind && measurement.imagesObj[0]) {
          measurementClass = 'green';
        } else {
          measurementClass = 'red';
        }
      }
      return measurementClass;
    },
    downloadArchive() {
      this.$emit('download-archive');
    },
    downloadReportPhotos() {
      this.$emit('download-report-photos');
    },
    downloadReport() {
      this.$emit('download-report');
    },
    getRusTag(key, value) {
      if (key === 'dateTime') {
        return `${this.$t('interface.from')} ${value.from} ${this.$t('interface.to').toLowerCase()} ${value.to}`;
      }
      if (key === 'length') {
        return `${this.$t('measurement_filter.length')} ${this.$t('interface.from').toLowerCase()} ${value.from} ${this.$t('interface.to').toLowerCase()} ${value.to} ${this.$t('interface.m')}`;
      }
      if (key === 'width') {
        return `${this.$t('measurement_filter.width')} ${this.$t('interface.from').toLowerCase()} ${value.from} ${this.$t('interface.to').toLowerCase()} ${value.to} ${this.$t('interface.m')}`;
      }
      if (key === 'height') {
        return `${this.$t('measurement_filter.height')} ${this.$t('interface.from').toLowerCase()} ${value.from} ${this.$t('interface.to').toLowerCase()} ${value.to} ${this.$t('interface.m')}`;
      }
      if (key === 'speed') {
        return `${this.$t('measurement_filter.speed')} ${this.$t('interface.from').toLowerCase()} ${value.from} ${this.$t('interface.to').toLowerCase()} ${value.to} ${this.$t('interface.kmh')}`;
      }
      if (key === 'weight') {
        return `${this.$t('measurement_filter.total_weight')} ${this.$t('interface.from').toLowerCase()} ${value.from} ${this.$t('interface.to').toLowerCase()} ${value.to} ${this.$t('interface.t')}`;
      }
      if (key === 'SVPDateTime') {
        return `${this.$t('measurement_filter.date_send')} ${this.$t('interface.from').toLowerCase()} ${value.from} ${this.$t('interface.to').toLowerCase()} ${value.to}`;
      }
      if (key === 'autodorClass') {
        return `${this.$t('panel_cols.class_autodor')}: ${value.join(', ')}`;
      }
      if (key === 'zsdClass') {
        return `${this.$t('panel_cols.class_zsd')}: ${value.join(', ')}`;
      }
      if (key === 'lanes') {
        return `${this.$t('panel_cols.lane')}: ${value.join(', ')}`;
      }
      if (key === 'dsrcTypes') {
        return `DSRC: ${value.join(', ')}`;
      }
      if (key === 'manual') {
        return `${this.$t('measurement_filter.manual_analysis')}: ${value.map((item) => this.$t(`measurement_filter.${item}`)).join(', ')}`;
      }
      if (key === 'selectedEctn28classes') {
        return `${this.$t('panel_cols.ectn28')}: ${value.join(', ')}`;
      }
      if (key === 'selectedRus12categories') {
        return `${this.$t('panel_cols.rus12_category')}: ${value.join(', ')}`;
      }
      if (key === 'selectedRus12subcategories') {
        return `${this.$t('panel_cols.rus12_subcategory')}: ${value.join(', ')}`;
      }
      if (key === 'selectedIndClasses') {
        return `${this.$t('panel_cols.class_ind')}: ${value.join(', ')}`;
      }
      if (key === 'selectedCountries') {
        return `${this.$t('panel_cols.country')}: ${value.map((item) => item.code).join(', ')}`;
      }
      if (key === 'selectedViolationsOptions') {
        return `${this.$t('panel_cols.violations')}: ${value.map((item) => this.$t(`measurement_filter.violations.${item.code}`)).join(', ')}`;
      }
      if (key === 'no_category') {
        return `${this.$t('measurement_filter.no_ectn')}`;
      }
      if (key === 'nearby_dsrc') {
        return `${this.$t('measurement_filter.nearby_dsrc')}`;
      }
      if (key === 'notRUS') {
        return `${this.$t('measurement_filter.all_no_rus')}`;
      }
      if (key === 'many_pan') {
        return `${this.$t('measurement_filter.many_pan')}`;
      }
      if (key === 'noGRZ') {
        return `${this.$t('measurement_filter.no_gnz')}`;
      }
      if (key === 'noRearGRZ') {
        return `${this.$t('measurement_filter.no_back_gnz')}`;
      }
      if (key === 'pan') {
        return `PAN: ${value}`;
      }
      if (key === 'gnz') {
        return `${this.$t('measurement_filter.gnz')}: ${value}`;
      }
      if (key === 'measurement_id') {
        return `${this.$t('measurement_filter.trans_id')}: ${value}`;
      }
      if (key === 'front_back_plates_diff_not_1') {
        return `${this.$t('measurement_filter.front_and_rear')}`;
      }
      if (key === 'with_trailers') {
        return `${this.$t('measurement_filter.excluding_railers')}`;
      }
      if (key === 'roadar_prob_not_1') {
        return `${this.$t('measurement_filter.recognition_mismatch')}`;
      }
      if (key === 'ton12') {
        return `${this.$t('measurement_filter.more_than_12')}`;
      }
      if (key === 'rfid') {
        return `${this.$t('measurement_filter.rfid_availability')}`;
      }
      if (key === 'no_camera') {
        return `${this.$t('measurement_filter.no_photo')}`;
      }
      if (key === 'image_request') {
        return `${this.$t('measurement_filter.dozapros')}`;
      }
      if (key === 'viol_pdd') {
        return `${this.$t('measurement_filter.pdd')}`;
      }
      if (key === 'viol_speed') {
        return `${this.$t('measurement_filter.viol_speed')}`;
      }
      if (key === 'viol_speed20') {
        return `${this.$t('measurement_filter.viol_speed20')}`;
      }
      if (key === 'viol_speed40') {
        return `${this.$t('measurement_filter.viol_speed40')}`;
      }
      if (key === 'viol_speed60') {
        return `${this.$t('measurement_filter.viol_speed60')}`;
      }
      if (key === 'viol_speed80') {
        return `${this.$t('measurement_filter.viol_speed80')}`;
      }
      if (key === 'viol_collision') {
        return `${this.$t('measurement_filter.viol_collision')}`;
      }
      if (key === 'viol_dimensions') {
        return `${this.$t('measurement_filter.viol_dimensions')}`;
      }
      if (key === 'viol_weight') {
        return `${this.$t('measurement_filter.viol_weight')}`;
      }
      if (key === 'sdkpClass') {
        return `${this.$t('measurement_filter.class_sdkp')} ${value.join(', ')}`;
      }
      if (key === 'axles') {
        return `${this.$t('measurement_filter.axles')} ${value.operator} ${value.cnt}`;
      }
      if (key === 'same_gnz') {
        return `${this.$t('measurement_filter.same_gnz')}`;
      }
      if (key === 'same_gnz_2') {
        return `${this.$t('measurement_filter.same_gnz_2')}`;
      }
      if (key === 'same_pan') {
        return `${this.$t('measurement_filter.same_pan')}`;
      }
      if (key === 'weighter_validity') {
        return `${this.$t('measurement_filter.weighter_validity')}: ${value.map((item) => `${this.$t(`measurement_modal.weighter_validity.${item.code}`)}`).join(', ')}`;
      }
      if (key === 'selectedWeighterValidity') {
        return `${this.$t('measurement_filter.weighter_validity')}: ${value.map((item) => `${this.$t(`measurement_modal.weighter_validity.${item.code}`)}`).join(', ')}`;
      }
      if (key === 'weighter_validity_0') {
        return `${this.$t('measurement_modal.weighter_validity.valid')}`;
      }
      if (key === 'weighter_validity_1') {
        return `${this.$t('measurement_modal.weighter_validity.1')}`;
      }
      if (key === 'weighter_validity_2') {
        return `${this.$t('measurement_modal.weighter_validity.2')}`;
      }
      if (key === 'weighter_validity_3') {
        return `${this.$t('measurement_modal.weighter_validity.3')}`;
      }
      if (key === 'weighter_validity_4') {
        return `${this.$t('measurement_modal.weighter_validity.4')}`;
      }
      if (key === 'weighter_validity_5') {
        return `${this.$t('measurement_modal.weighter_validity.5')}`;
      }
      if (key === 'weighter_validity_6') {
        return `${this.$t('measurement_modal.weighter_validity.6')}`;
      }
      if (key === 'validity_first_axle') {
        return `${this.$t('measurement_modal.weighter_validity.1')}`;
      }
      if (key === 'validity_half_axle_max') {
        return `${this.$t('measurement_modal.weighter_validity.2')}`;
      }
      if (key === 'validity_axles_speed') {
        return `${this.$t('measurement_modal.weighter_validity.3')}`;
      }
      if (key === 'validity_speed_in_range') {
        return `${this.$t('measurement_modal.weighter_validity.4')}`;
      }
      if (key === 'validity_lane_change') {
        return `${this.$t('measurement_modal.weighter_validity.5')}`;
      }
      if (key === 'validity_axle_distance') {
        return `${this.$t('measurement_modal.weighter_validity.6')}`;
      }
      if (key === 'no_vehicle_no_ectn') {
        return `${this.$t('measurement_filter.no_vehicle_no_ectn')}`;
      }
      if (key === 'weighter_validity_7') {
        return `${this.$t('measurement_modal.weighter_validity.7')}`;
      }
      if (key === 'weighter_validity_8') {
        return `${this.$t('measurement_modal.weighter_validity.8')}`;
      }
      if (key === 'weighter_validity_9') {
        return `${this.$t('measurement_modal.weighter_validity.9')}`;
      }
      if (key === 'weighter_validity_10') {
        return `${this.$t('measurement_modal.weighter_validity.10')}`;
      }
      if (key === 'weighter_validity_11') {
        return `${this.$t('measurement_modal.weighter_validity.11')}`;
      }
      if (key === 'weighter_validity_12') {
        return `${this.$t('measurement_modal.weighter_validity.12')}`;
      }
      if (key === 'weighter_validity_13') {
        return `${this.$t('measurement_modal.weighter_validity.13')}`;
      }
      if (key === 'weighter_validity_14') {
        return `${this.$t('measurement_modal.weighter_validity.14')}`;
      }
      if (key === 'weighter_validity_15') {
        return `${this.$t('measurement_modal.weighter_validity.15')}`;
      }
      if (key === 'weighter_validity_16') {
        return `${this.$t('measurement_modal.weighter_validity.16')}`;
      }
      if (key === 'weighter_validity_17') {
        return `${this.$t('measurement_modal.weighter_validity.17')}`;
      }
      if (key === 'weighter_validity_18') {
        return `${this.$t('measurement_modal.weighter_validity.18')}`;
      }
      if (key === 'weighter_validity_19') {
        return `${this.$t('measurement_modal.weighter_validity.19')}`;
      }
      if (key === 'weighter_validity_20') {
        return `${this.$t('measurement_modal.weighter_validity.20')}`;
      }
      if (key === 'weighter_validity_21') {
        return `${this.$t('measurement_modal.weighter_validity.21')}`;
      }
      if (key === 'weighter_validity_22') {
        return `${this.$t('measurement_modal.weighter_validity.22')}`;
      }
      if (key === 'weighter_validity_23') {
        return `${this.$t('measurement_modal.weighter_validity.23')}`;
      }
      if (key === 'weighter_validity_24') {
        return `${this.$t('measurement_modal.weighter_validity.24')}`;
      }
      if (key === 'weighter_validity_25') {
        return `${this.$t('measurement_modal.weighter_validity.25')}`;
      }
      if (key === 'weighter_validity_26') {
        return `${this.$t('measurement_modal.weighter_validity.26')}`;
      }
      if (key === 'weighter_validity_27') {
        return `${this.$t('measurement_modal.weighter_validity.27')}`;
      }
      if (key === 'weighter_validity_28') {
        return `${this.$t('measurement_modal.weighter_validity.28')}`;
      }
      return `${key} - ${value}`;
    },
  },
};
