import Vue from 'vue';
import VueNativeSock from 'vue-native-websocket';
import ViewItem from '@/components/ViewItem.vue';
import vSelect from 'vue-select';
import * as uiv from 'uiv';
import VueI18n from 'vue-i18n';
import ru from './lang/ru.json';
import en from './lang/en.json';
import tr from './lang/tr.json';
import id from './lang/id.json';
import App from './App.vue';
import router from './router';
import store from './store';
import './assets/sass/style.scss';

import inputMask from './directives/inputMask';

Vue.component('v-select', vSelect);
Vue.component(ViewItem.name, ViewItem);

Vue.use(uiv);

const url = process.env.NODE_ENV === 'development'
  ? 'ws://10.82.1.66:8765/'
  : `ws://${window.location.hostname}:8765/`;

Vue.use(VueNativeSock, url, {
  reconnection: true,
  reconnectionAttempts: Infinity,
  reconnectionDelay: 3000,
});

Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: (localStorage.getItem('lang') || 'ru'),
  messages: {
    ru,
    en,
    tr,
    id,
  },
});

Vue.directive('mask', inputMask);

new Vue({
  i18n,
  store,
  router,
  render: (h) => h(App),
}).$mount('#app');
